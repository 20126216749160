import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Seo from "../components/seo"
import HeaderPages from "../components/header-pages"
import TermsCard from "../components/cards/terms-card"
import axios from 'axios'
import { API } from "../config/api"

const PrivacyPolicy = () => {
  const [Data, setData] = useState()
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API.URL}/api/v1/faq/list-terms`
      )
      setData(response.data.data)
    } catch (error) {
      return {
        status: false,
        response: null,
        messages: error.message,
      }
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Layout pageTitle="Syarat & Ketentuan | Klabbelanja">
      <Seo title="Syarat & Ketentuan | Klabbelanja" />
      <HeaderPages />
      <div style={{ height: 150 }} />
      {Data?.map((item) => (
        <TermsCard title={item?.header} route={item?.flag} />
      ))}
      {/* <DataTermCondition /> */}
      {/* <Footer /> */}
    </Layout>
  )
}

export default PrivacyPolicy
